import React, { useContext } from 'react'
import PageContext from '@context'
import SbEditable from 'storyblok-react'
import renderBloks from '@renderBloks'
import { makeStyles } from '@material-ui/styles'
import { Box, useMediaQuery } from '@material-ui/core'
import { Text, TextSm } from '@system'
import classNames from 'classnames'
import { Link as GatsbyLink } from 'gatsby'
import ACC_THEME from '@themes'
import multilinkToUrl from '@helpers/multilink-to-url'
import get from 'lodash/get'
import formatLocalizedLink from '@helpers/format-localized-link'
import useStringTranslation from '@hooks/use-string-translation'
import ACSResourceCard from '@system/resource-card'

const useStyles = makeStyles((theme) => ({
  resourceCard: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: ({ cardModuleMaxWidth }) => cardModuleMaxWidth || '20rem',
    margin: (props) => (props.blok.removeMargin ? '0px' : '3rem 1rem'),
    textDecoration: 'none',
    position: 'relative',
  },
  referenceStyle: {
    maxHeight: '350px',
    padding: '25px 8px',
  },
  leftAlign: {
    textAlign: 'left',
    justifyContent: 'flex-start',
  },
  rightAlign: {
    textAlign: 'right',
    justifyContent: 'flex-end',
  },
  centerAlign: {
    textAlign: 'center',
  },
  imageContainer: {
    width: '100%',
    marginBottom: '1rem',
  },
  title: (props) => ({
    color:
      !!props.blok.titleColor && !!props.blok.titleColor.color
        ? props.blok.titleColor.color
        : theme.palette.text.tertiary,
    fontWeight: theme.typography.fontWeightBold,
    padding: props.blok.noDescMargin ? '0px' : '20px 0',
    display: 'block',
  }),
  description: ({ variants }) => ({
    color:
      variants === 'black-background'
        ? theme.palette.common.white
        : theme.palette.text.tertiary,
    paddingBottom: '15px',
    [theme.breakpoints.up('sm')]: {
      paddingBottom: '25px',
    },
    display: 'block',
  }),
  link: {
    paddingBottom: '5px',
    textTransform: 'uppercase',
    marginTop: 'auto',
  },
  abmLink: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.tertiary,
    paddingBottom: '5px',
    textTransform: 'uppercase',
    marginTop: 'auto',
    '& a': {},
  },
  ctaModule: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    padding: '12px',
    textAlign: 'center',
    fontWeight: theme.typography.fontWeightBold,
    '& button': {
      padding: '4px 12px',
    },
    '& a': {
      padding: '4px 8px',
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: '1rem',
      display: 'block',
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: '1.5rem',
    },
  },
  noPaddingDescription: {
    paddingBottom: '0px',
  },
}))
const ResourceCard = (props) => {
  const classes = useStyles(props)
  const {
    title,
    description,
    alignment,
    buttonAssetUrl,
    variant,
    image,
    noDescMargin,
    isClickable,
  } = props.blok

  const { isInEditor } = useContext(PageContext)
  const { thumbnailHeight, cardModuleMaxWidth } = props
  const alignmentLeft = alignment === 'left'
  const alignmentCenter = alignment === 'center'
  const alignmentRight = alignment === 'right'
  const handHeldDevice = useMediaQuery(ACC_THEME.breakpoints.down('sm'))
  const isReferenceVariant = variant === 'referenceStyle'
  const assetUrl = get(buttonAssetUrl, '[0]')
  const resourceCardLink =
    assetUrl?.link?.story?.full_slug || assetUrl?.link?.url || ''
  const textToHyperlink = useStringTranslation(
    assetUrl?.textToHyperlink || assetUrl?.text || 'Read'
  )

  return (
    <SbEditable content={props.blok}>
      {isReferenceVariant ? (
        <ACSResourceCard
          blok={{
            ...props.blok,
            textToHyperlink: textToHyperlink,
            isWebinarsModule: false,
            link: formatLocalizedLink(resourceCardLink),
          }}
        />
      ) : !handHeldDevice || props.variants === 'black-background' ? (
        <>
          {!isClickable ? (
            <Box
              className={classNames(classes.resourceCard, {
                [classes.leftAlign]: alignmentLeft,
                [classes.rightAlign]: alignmentRight,
                [classes.centerAlign]: alignmentCenter,
              })}
            >
              <Box>
                {!!image && image.length > 0 && (
                  <Box className={classes.imageContainer}>
                    {renderBloks(image, {
                      thumbnailHeight,
                      cardModuleMaxWidth,
                    })}
                  </Box>
                )}
                {!!title && (
                  <Text
                    className={classNames(classes.title, {
                      [classes.noPaddingDescription]: noDescMargin,
                    })}
                  >
                    {title}
                  </Text>
                )}
                {!!description && (
                  <TextSm className={classes.description}>{description}</TextSm>
                )}
              </Box>
              <Box>
                <TextSm
                  component="span"
                  className={classNames(classes.link, classes[variant])}
                >
                  {renderBloks(buttonAssetUrl)}
                </TextSm>
              </Box>
            </Box>
          ) : (
            <Box
              component={GatsbyLink}
              rel={'noreferrer'}
              target={'_blank'}
              href={
                buttonAssetUrl[0].component === 'button'
                  ? multilinkToUrl(buttonAssetUrl[0].linksTo, isInEditor)
                  : multilinkToUrl(buttonAssetUrl[0].link, isInEditor)
              }
              className={classNames(classes.resourceCard, {
                [classes.leftAlign]: alignmentLeft,
                [classes.rightAlign]: alignmentRight,
                [classes.centerAlign]: alignmentCenter,
              })}
            >
              <Box>
                {!!image && image.length > 0 && (
                  <Box className={classes.imageContainer}>
                    {renderBloks(image, {
                      thumbnailHeight,
                      cardModuleMaxWidth,
                    })}
                  </Box>
                )}
                {!!title && (
                  <Text
                    className={classNames(classes.title, {
                      [classes.noPaddingDescription]: noDescMargin,
                    })}
                  >
                    {title}
                  </Text>
                )}
                {!!description && (
                  <TextSm className={classes.description}>{description}</TextSm>
                )}
              </Box>
              <Box>
                <TextSm
                  component="span"
                  className={classNames(classes.link, classes[variant])}
                >
                  {renderBloks(buttonAssetUrl)}
                </TextSm>
              </Box>
            </Box>
          )}
        </>
      ) : (
        <>
          {!isClickable ? (
            <Box
              style={{
                marginBottom: '24px',
              }}
              className={classNames(classes.resourceCard, {
                [classes.leftAlign]: alignmentLeft,
                [classes.rightAlign]: alignmentRight,
                [classes.centerAlign]: alignmentCenter,
              })}
            >
              <Box>
                {!!title && (
                  <Text
                    className={classNames(classes.title, {
                      [classes.noPaddingDescription]: noDescMargin,
                    })}
                  >
                    {title}
                  </Text>
                )}
                {!!description && (
                  <TextSm className={classes.description}>{description}</TextSm>
                )}
              </Box>
              <Box>
                <TextSm
                  component="span"
                  className={classNames(classes.link, classes[variant])}
                >
                  {renderBloks(buttonAssetUrl)}
                </TextSm>
              </Box>
            </Box>
          ) : (
            <Box
              component={GatsbyLink}
              rel={'noreferrer'}
              style={{
                marginBottom: '24px',
              }}
              target={'_blank'}
              href={
                buttonAssetUrl[0].component === 'button'
                  ? buttonAssetUrl[0].linksTo &&
                    multilinkToUrl(buttonAssetUrl[0].linksTo, isInEditor)
                  : buttonAssetUrl[0].link &&
                    multilinkToUrl(buttonAssetUrl[0].link, isInEditor)
              }
              className={classNames(classes.resourceCard, {
                [classes.leftAlign]: alignmentLeft,
                [classes.rightAlign]: alignmentRight,
                [classes.centerAlign]: alignmentCenter,
              })}
            >
              <Box>
                {!!title && (
                  <Text
                    className={classNames(classes.title, {
                      [classes.noPaddingDescription]: noDescMargin,
                    })}
                  >
                    {title}
                  </Text>
                )}
                {!!description && (
                  <TextSm className={classes.description}>{description}</TextSm>
                )}
              </Box>
              <Box>
                <TextSm
                  component="span"
                  className={classNames(classes.link, classes[variant])}
                >
                  {renderBloks(buttonAssetUrl)}
                </TextSm>
              </Box>
            </Box>
          )}
        </>
      )}
    </SbEditable>
  )
}

export default ResourceCard
